<template v-if="cv">
  <table class="table">
    <tbody>
      <tr>
        <td>
          <article v-if="cv">
            <section id="heading">
              <div class="gradientBoxColor">
                <div class="gradientBoxGrey" />
              </div>

              <div class="heading-content">
                <a-avatar
                  v-if="showGravatar && cv.gravatar && cv.gravatar.length > 1"
                  class="heading-avatar"
                  :src="cv.gravatar"
                  :size="90"
                />
                <div class="heading-titles">
                  <h2 class="heading-text">{{ cv.name }}</h2>
                  <h4 class="heading-text">{{ cv.first_title }}</h4>
                  <h4 class="heading-text">{{ cv.second_title }}</h4>
                  <div
                    v-if="showGithub && cv.github && cv.github.length > 1"
                    class="heading-github"
                  >
                    <a
                      :href="`https://www.github.com/${cv.github}`"
                      target="_blank"
                      class="heading-text"
                    >
                      <a-icon type="github" />
                      {{ cv.github }}
                    </a>
                  </div>
                </div>
                <img class="logo" :src="logo" />
              </div>
            </section>

            <section id="background">
              <h2>Where {{ cv.name.split(" ")[0] }} excels</h2>

              <p v-for="p in cvBackground" :key="p" class="background-content">
                {{ p }}
              </p>
            </section>

            <section id="characteristics">
              <span
                v-for="characteristic in cv.characteristics"
                :key="characteristic.id"
                class="cv-badge cv-badge-characteristic"
              >
                {{ characteristic.title }}
              </span>
            </section>

            <section v-if="cvMotivation" id="motivation">
              <h2>Why {{ cv.name.split(" ")[0] }} would be a good candidate</h2>

              <p v-for="p in cvMotivation" :key="p">
                {{ p }}
              </p>
            </section>

            <section v-if="showEducations && cv.educations && cv.educations.length" id="education">
              <h2>Educations</h2>
              <div v-for="education in cv.educations" :key="education.id" class="cv-card-wrapper">
                <div class="cv-card">
                  <h3 class="cv-card-title">
                    {{ education.title }} at {{ education.university_name }}
                  </h3>
                  <span class="cv-card-subtitle">{{
                    formatProjectDate(education.start_date, education.end_date)
                  }}</span>
                  <a-divider class="cv-card-divider" />
                  <p v-if="showEducationsDescription && cv" class="cv-card-body">
                    {{ education.description }}
                  </p>
                  <div v-if="showSkills" class="cv-card-meta">
                    <span
                      v-for="skill in education.skills"
                      :key="skill.id"
                      :class="{
                        'cv-badge-highlight':
                          showSkillHighlights &&
                          cv.skills.find((s) => s.id === skill.id)?.highlight,
                      }"
                      class="cv-badge cv-badge-project"
                    >
                      {{ skill.title }}
                    </span>
                  </div>
                </div>
              </div>
            </section>

            <section
              v-if="cv.knowledge_domains.length && showAreasOfExpertise"
              id="knowledge-domains"
            >
              <h2>Areas of Expertise</h2>

              <div v-for="domain in cv.knowledge_domains" :key="domain.id" class="cv-card-wrapper">
                <div class="cv-card">
                  <h3 class="cv-card-title">{{ domain.title }}</h3>
                  <span class="cv-card-subtitle">{{ domain.level }}</span>
                  <a-divider class="cv-card-divider" />
                  <p class="cv-card-body">{{ domain.description }}</p>
                </div>
              </div>
            </section>

            <section
              v-if="cv.skill_categories?.length && showSkillCategories"
              id="skill-categories"
            >
              <div class="cv-card-col-container">
                <div
                  v-for="category in cv.skill_categories"
                  :key="category.id"
                  class="cv-card-col-wrapper"
                >
                  <div class="cv-card cv-card-skill-group">
                    <h3 class="cv-card-title">{{ category.title }}</h3>
                    <a-divider class="cv-card-divider" />
                    <div class="cv-card-body" />
                    <div class="cv-card-meta">
                      <div
                        v-for="skill in category.skills"
                        :key="skill.id"
                        class="cv-badge cv-badge-project"
                        :class="{
                          'cv-badge-highlight':
                            showSkillHighlights &&
                            cv.skills.find((s) => s.id === skill.id)?.highlight,
                        }"
                      >
                        {{ skill.title }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <!-- Illustrative footer only highlighting end of first page, hidden during printing -->
            <div class="page-footer hide-print">
              <div class="titles">
                <h4>Thank you for your time!</h4>
                <h4>For inquiries please contact:</h4>
              </div>
              <div class="titles titles-right">
                <h4>{{ cv.contact_phone }}</h4>
                <h4>{{ cv.contact_email }}</h4>
              </div>
            </div>

            <!-- ------------------------ Front-page ends here -------------------------- -->

            <header id="second-page">
              <div class="gradientBoxColor">
                <div class="gradientBoxGrey" />
              </div>
              <div class="header-content">
                <img class="logo-header" :src="logo" />
              </div>
            </header>

            <section v-if="cv.skills?.length && !showSkillCategories" id="skills" class="">
              <h2>Languages, Tools, Frameworks</h2>
              <div class="cv-skills">
                <div
                  v-for="skill in cv.skills"
                  :key="skill.id"
                  class="cv-badge-skill cv-badge-draggable"
                  :class="{ 'cv-badge-highlight': showSkillHighlights && skill.highlight }"
                >
                  {{ skill.title }}
                </div>
              </div>
            </section>

            <section v-if="cv.roles.length || cv.work_experiences.length" id="projects">
              <h2>Selected projects</h2>
              <div
                v-for="role in (cv.roles || []).concat(cv.work_experiences || [])"
                :key="role.id"
                class="cv-card-wrapper"
              >
                <div class="cv-card">
                  <div class="cv-card-title">
                    <h3 class="no-margin">
                      {{
                        (role.client && !role.hide_client_name ? role.client : role.client_alias) ||
                        role.company_name
                      }}
                    </h3>
                    <p v-if="showDates">
                      {{ formatProjectDate(role.start_date, role.end_date) }}
                    </p>
                  </div>
                  <div class="cv-card-subtitle">
                    <span>{{ role.custom_role_title ?? role.title }}</span>
                    <p v-if="showDuration">
                      {{ formatProjectDuration(role.start_date, role.end_date) }}
                    </p>
                  </div>

                  <a-divider class="cv-card-divider" />

                  <div class="cv-card-body">
                    <a v-if="role.url_link" :href="`${role.url_link}`" target="_blank">
                      {{ role.url_link }}
                    </a>
                    <p>{{ role.description }}</p>
                  </div>

                  <div v-if="showSkills" class="cv-card-meta">
                    <span
                      v-for="skill in role.skills"
                      :key="skill.id"
                      :class="{
                        'cv-badge-highlight':
                          showSkillHighlights &&
                          cv.skills.find((s) => s.id === skill.id)?.highlight,
                      }"
                      class="cv-badge cv-badge-project"
                    >
                      {{ skill.title }}
                    </span>
                  </div>
                </div>
              </div>
            </section>

            <section v-if="cv.courses.length" id="courses">
              <h2>Certifications / Courses</h2>
              <div class="cv-courses">
                <div v-for="course in cv.courses" :key="course.id" class="cv-card-padding">
                  <div class="cv-card cv-card-strip">
                    <div>
                      <h3>{{ course.title }}</h3>
                      <p>{{ course.description }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div class="page-footer">
              <div class="titles">
                <h4>Thank you for your time!</h4>
                <h4>For inquiries please contact:</h4>
              </div>
              <div class="titles titles-right">
                <h4>{{ cv.contact_phone }}</h4>
                <h4>{{ cv.contact_email }}</h4>
              </div>
            </div>
          </article>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td>
          <div class="page-footer-space"></div>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { CvInterface } from "../../types";
import { whiteLogoDataURL } from "../../_utils/logoData";

const props = defineProps({
  cv: { type: Object as () => CvInterface, default: undefined },
  showDates: { type: Boolean, default: undefined },
  showDuration: { type: Boolean, default: undefined },
  showSkillCategories: { type: Boolean, default: undefined },
  showAreasOfExpertise: { type: Boolean, default: undefined },
  showSkills: { type: Boolean, default: undefined },
  showGithub: { type: Boolean, default: undefined },
  showGravatar: { type: Boolean, default: undefined },
  showEducations: { type: Boolean, default: undefined },
  showEducationsDescription: { type: Boolean, default: undefined },
  cvBackground: { type: Array as () => string[], default: () => [] },
  cvMotivation: { type: Array as () => string[] | null, default: () => [] },
  showSkillHighlights: { type: Boolean, default: false },
  formatProjectDate: {
    type: Function,
    default: (start: any, end: any) => String,
  },
  formatProjectDuration: {
    type: Function,
    default: (start: string, end: string) => String,
  },
});
// Data properties
const logo = computed<string>(() => whiteLogoDataURL());
</script>

<style lang="scss" scoped>
// Do not use break-after: page or page-break-after: always since it will end up breaking rendering of tfoot
// This is needed in order get correct space for page footers (valid as of 2023-08-30 on chrome 116)

$background: #f4f4f4;
$text: #000000;
$text-faded: #7a7a7a;
$dark-gray: #22292f;
$default-padding: 3em;
$default-border: 1px solid #ebebeb;
$heading-height: 34mm;
$footer-height: 15mm;

$cv-badge-spacing: 0.5em;
$cv-card-spacing: 1em;

@page {
  margin: 0;
  size: a4 portrait;
}

@mixin shadow-border {
  @media print {
    border: $default-border;
    border-bottom-width: 1.5px;
  }
}

h1 {
  font-size: 2.2em;
  font-weight: normal;
}

h2 {
  font-size: 16pt;
  font-weight: normal;
}

h3 {
  font-size: 12pt;
  font-weight: bold;

  &.no-margin {
    margin: 0;
  }
}

h4 {
  font-size: 8pt;
  font-weight: normal;

  h4 ~ & {
    margin-bottom: 0;
  }
}

h1,
h2,
h3,
h4,
h5 {
  color: $text;
  break-after: avoid;
}

.cv-link {
  color: grey;
  font-weight: normal;
}

.table {
  background-color: $background;

  @media print {
    position: absolute;
  }
}

article {
  position: relative;
  background: $background;
  color: $text;
  font-size: 11px;
  line-height: 1.5;
  overflow: hidden;
  font-family: "ABCWhyte";
  font-feature-settings: normal;
  font-variant: normal;

  @media not print {
    width: 210mm;
    margin: auto;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  }
}

section {
  padding: $default-padding $default-padding 0;
  &:last-of-type {
    padding-bottom: $default-padding;
  }

  &#heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $heading-height;
    background: white;
    border-bottom: $default-border;
    border-bottom-width: 2px;
    padding: 0;

    .heading-avatar {
      margin-left: 5mm;
    }

    .heading-titles {
      display: flex;
      flex-direction: column;
      margin-left: 5mm;
      justify-content: center;
    }

    .heading-content {
      position: absolute;
      display: flex;
      z-index: 1;
    }

    .heading-text {
      margin: 0;
      color: white;
      @media print {
        -webkit-print-color-adjust: exact;
      }
    }

    .heading-github {
      display: flex;
      vertical-align: middle;
    }
  }

  &#background {
    .background-content {
      margin-top: 5mm;
      padding-top: 0;
    }
  }

  &#characteristics {
    margin-top: 10mm;
    padding-top: 0;
  }
}

.logo {
  width: 205px;
  height: 100%;
  margin-left: 100mm;
  position: absolute;

  &-header {
    width: 17em;
    margin-top: 2mm;
    margin-left: 100mm;
  }
}

.gradientBoxColor {
  width: 100%;
  height: 100%;
  position: relative;
  background-image: radial-gradient(
    100% 150% at 55% 100%,
    #f7a1a1 10%,
    #f7a1a1 0%,
    #ffd8d6 60%,
    #ffd8d6 110%
  );

  @media print {
    -webkit-print-color-adjust: exact;
  }
}

.gradientBoxGrey {
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #a5a7a8, #a5a7a8 60%, transparent 60%, transparent 100%);
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

.cv-card-wrapper {
  &:not(:first-of-type) {
    padding-top: $cv-card-spacing;
    overflow: visible;
  }

  @media print {
    break-inside: avoid-page;
  }
}

.cv-card-padding {
  padding-top: $cv-card-spacing;
}

.cv-card {
  flex-wrap: wrap;
  background: white;
  padding: 5mm;
  display: flex;
  height: 100%;

  @include shadow-border;

  &-skill-group {
    display: block;
  }

  &-strip {
    padding: 3mm;
    padding-left: 5mm;
  }

  &-title {
    margin-left: -2, 5mm;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-subtitle {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    right: 15mm;
  }

  &-divider {
    margin: 0;
    margin-top: 2mm;
    @include shadow-border;
  }

  &-body {
    flex: 3;
    margin-top: 5mm;
  }

  &-meta {
    flex-basis: 100%;
  }
}

.cv-badge {
  display: inline-block;
  background: white;
  padding: 0.5em 1.25em;

  @include shadow-border;

  &:not(:last-of-type) {
    margin-right: $cv-badge-spacing;
  }

  &-characteristic {
    min-width: 30mm;
    text-align: center;

    @include shadow-border;
  }

  &-skill {
    $gutter: calc($cv-badge-spacing / 2);
    display: inline-block;
    width: calc(25% - #{$gutter * 2});
    background: white;
    margin: $gutter;
    padding: 0.75em;
    text-align: center;

    @include shadow-border;
  }

  &-project {
    min-width: 25mm;
    text-align: center;
    background: rgba(240, 240, 240, 255);
    border-radius: 50px;
    margin-bottom: $cv-badge-spacing;
    margin-right: $cv-badge-spacing !important;
  }

  &-rounded {
    border-radius: 100px;
  }

  &-grey {
    background: rgba(240, 240, 240, 255);
  }

  &-highlight {
    font-weight: bolder;
  }
}

header#second-page {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 24mm;
  padding: 0;
  break-before: page;

  .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 10;
  }

  .header-title {
    font-size: 1.5em;
    margin: 0;
    color: white;
    position: absolute;
  }
}

.page-footer,
.page-footer-space {
  height: $footer-height;
}

.page-footer {
  background: $background;
  color: $text;
  position: fixed;
  bottom: 0;
  width: 100%;

  padding-left: 10mm;
  padding-right: 10mm;

  display: flex;
  justify-content: space-between;

  border-top: $default-border;
  border-top-width: 1px;

  @media not print {
    position: relative;
    bottom: 0;
  }

  p {
    font-size: 1.25em;
    margin-bottom: 0.25em;
  }

  .titles {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &-right {
      text-align: right;
    }
  }
}

.hide-print {
  @media print {
    display: none;
  }
}

.cv-card-col-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.cv-card-col-wrapper {
  flex: 0 0 calc(50% - 10px);

  @media print {
    break-inside: avoid-page;
  }
}
</style>
